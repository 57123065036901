<template>
  <div class="review-settings">
    <GlobalInfoBar
      title="审单报价设置"
      content="可在此处设置和查看审单报价的回收商商家和管理订单的审单异常项及应用的门店商家"
    />
    <div class="merchant-list">
      <div class="title">审单商家列表</div>
      <div class="flex flex-je">
        <el-button size="small" type="primary" @click="openAddMerchantDia"
          >添加回收商</el-button
        >
      </div>
      <div>
        <el-form ref="merchantForm" :model="merchantForm" inline size="small">
          <el-form-item label="回收商" prop="merchantId">
            <el-select
              v-model="merchantForm.merchantId"
              clearable
              filterable
              placeholder="请输入回收商名称查询"
            >
              <el-option
                v-for="item in merchantList"
                :key="item.merchantId"
                :label="item.merchantName"
                :value="item.merchantId"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" round :loading="merchantLoading" @click="searchMerchant()"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <GlobalTable
          ref="GlobalTable"
          v-loading="merchantLoading"
          isIndex
          :columns="merchantColumns"
          :data="merchantDataList"
          :currentPage="merchantForm.pageNum"
          :pageSize="merchantForm.pageSize"
          :total="merchantForm.total"
          @handleCurrentChange="searchMerchant"
        >
          <el-table-column
            label="操作人"
            slot="openExamineQuoteAdmin"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.openExamineQuoteAdmin }}-{{ row.openExamineQuoteAdminAcc }}
            </template>
          </el-table-column>
          <el-table-column
            label="添加时间"
            slot="openExamineQuoteTime"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.openExamineQuoteTime || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="商家状态" slot="isEnable" align="center">
            <template slot-scope="{ row }">
              <span :style="!row.isEnable ? 'color:#ff8080;' : ''">{{
                !row.isEnable ? "禁用" : "启用"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="水印状态"
            slot="isOpenExamineQuoteWatermark"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-switch
                inactive-color="#bebebe"
                @change="handleSwitch(row)"
                v-model="row.isOpenExamineQuoteWatermark"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="无法开机推单" slot="isExamineUnStart" align="center">
            <template slot-scope="{ row }">
              <el-switch
                inactive-color="#bebebe"
                @change="handleStartSwitch(row)"
                v-model="row.isExamineUnStart"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" slot="operate" align="center">
            <template slot-scope="{ row }">
              <el-button type="danger" size="small" @click="delmerchant(row)"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
    </div>
    <el-dialog
      title="回收商列表"
      :visible.sync="addMerchantDiaShow"
      :close-on-click-modal="false"
      width="1200px"
      top="2%"
      @closed="addMerchantDiaClose"
    >
      <div style="display:flex;justify-content: space-between;">
        <div style="width: 630px;margin-right:20px">
          <el-form :inline="true" :model="diaMerchantForm">
            <el-form-item label="回收商" prop="merchantId">
              <el-select
                size="small"
                v-model="diaMerchantForm.merchantId"
                clearable
                filterable
                placeholder="请输入回收商名称查询"
              >
                <el-option
                  v-for="item in merchantList"
                  :key="item.merchantId"
                  :label="item.merchantName"
                  :value="item.merchantId"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                @click="dialogSearchMerchant(1)"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
          <el-table
            v-if="addMerchantDiaShow"
            ref="addMerchantDia"
            :data="dialogTableData"
            v-loading="merchantDiaLoading"
            tooltip-effect="dark"
            border
            max-height="540"
            @selection-change="handSelect"
            :row-key="
              (row) => {
                return row.merchantId;
              }
            "
          >
            <el-table-column
              type="selection"
              :reserve-selection="true"
              align="center"
            >
            </el-table-column>
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="50"
              :index="
                (index) => {
                  return (
                    index +
                    1 +
                    (diaMerchantForm.pageNum - 1) * diaMerchantForm.pageSize
                  );
                }
              "
            />
            <el-table-column label="回收商名称" align="center">
              <template slot-scope="{ row }">{{
                row.merchantName || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="联系人" align="center">
              <template slot-scope="{ row }">{{
                row.contactName || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="联系电话" align="center">
              <template slot-scope="{ row }">{{
                row.contactPhone || "--"
              }}</template>
            </el-table-column>
          </el-table>
          <el-pagination
            style="text-align: right; margin-top: 20px"
            @current-change="dialogSearchMerchant"
            :current-page="diaMerchantForm.pageNum"
            layout="total,  prev, pager, next, jumper"
            :total="diaMerchantForm.total"
            :background="true"
          >
          </el-pagination>
        </div>
        <div style="width: 520px;">
          <p style="margin-bottom:20px">
            已选商户({{ selectedMerchantList.length }})
          </p>
          <el-table
            ref="addtipleTable"
            :data="selectedMerchantList"
            tooltip-effect="dark"
            border
            max-height="640"
            style="width: 100%"
          >
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="50"
            >
            </el-table-column>
            <el-table-column label="回收商名称" align="center">
              <template slot-scope="{ row }">{{
                row.merchantName || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="联系人" align="center">
              <template slot-scope="{ row }">{{
                row.contactName || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="联系电话" align="center">
              <template slot-scope="{ row }">{{
                row.contactPhone || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="{ row }">
                <p
                  @click="detaleCompy(row)"
                  style="color:red;cursor: pointer;text-decoration:underline"
                >
                  删除
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click.native="
            addMerchantDiaShow = false;
            selectedMerchantList = [];
          "
          >取消</el-button
        >
        <el-button type="primary" @click.native="addMerchantSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="安全验证"
      :visible.sync="commandDisableShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="commandClosed"
    >
      <div v-if="commandType === 'del'" style="margin-bottom: 20px;">
        <span style="color: #ff8695;font-size: 14px;">移除后，需在系统管理-菜单管理中隐藏该商家的审单报价菜单</span>
      </div>
      <div v-if="commandType === 'start'" style="margin-bottom: 20px;">
        <span style="color: #ff8695;font-size: 14px;" v-if="rowObj.isExamineUnStart">开启后，注意通知回收商在内爆后方添加一个无法开机的成色</span>
      </div>
      <div class="tip-centent">
        <span>{{ commandTipText }}</span>
      </div>
      <div class="command">
        <div style="width: max-content">谷歌验证码：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandDisableShow = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="commandSureAdd"
          :loading="commandButLoading"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  data() {
    return {
      merchantLoading: false,
      merchantForm: {
        merchantId: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      merchantList: [],
      merchantDataList: [],
      merchantColumns: [
        { label: "回收商名称", prop: "merchantName" },
        { label: "联系人", prop: "contactName" },
        { label: "联系电话", prop: "contactPhone" },
        // { label: "审单机型数量", prop: "reviewModelNum" },
        // { label: "审单员数量", prop: "reviewpersonNum" },
        // { slotName: "isReviewOrder" },
        { slotName: "openExamineQuoteAdmin" },
        { slotName: "openExamineQuoteTime" },
        { slotName: "isEnable" },
        { slotName: "isOpenExamineQuoteWatermark" },
        { slotName: "isExamineUnStart" },
        { slotName: "operate" },
      ],
      addMerchantDiaShow: false,
      merchantDiaLoading: false,
      dialogTableData: [],
      selectedMerchantList: [],
      diaMerchantForm: {
        merchantId: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      tableMerchantColumns: [
        { label: "回收商名称", prop: "merchantName" },
        { label: "联系人", prop: "contactName" },
        { label: "联系电话", prop: "contactPhone" },
      ],
      // 安全验证
      commandTipText: "",
      commandType: "",
      commandDisableShow: false,
      command: "",
      commandButLoading: false,
      rowObj: {}, //操作当前行
    };
  },
  created() {
    this.searchMerchant();
    this.getMerchantList();
  },
  methods: {
    getMerchantList() {
      _api.getMerchantSelectList().then((res) => {
        this.merchantList = res.data;
      });
    },
    // 查询商家
    searchMerchant(pageNum) {
      this.merchantLoading = true;
      _api
        .examineQuoteList({
          ...this.merchantForm,
          isOpenExamineQuote: true,
          pageNum,
        })
        .then((res) => {
          this.merchantDataList = res.data.records;
          this.merchantForm.pageNum = res.data.current;
          this.merchantForm.total = res.data.total;
          if(this.merchantDataList.length === 0 && this.merchantForm.pageNum > 1){
            this.searchMerchant(this.merchantForm.pageNum-1)
          }
        })
        .finally(() => {
          this.merchantLoading = false;
        });
    },
    // 水印状态切换
    handleSwitch(row) {
      this.commandTipText = row.isOpenExamineQuoteWatermark?'是否确定开启该商家水印？':'是否确定关闭该商家水印？';
      this.commandType = "water";
      this.commandDisableShow = true;
      this.rowObj = row
    },
    handleStartSwitch(row) {
      this.commandTipText = row.isExamineUnStart?'是否确定开启该商家无法开机推单？':'是否确定关闭该商家无法开机推单？';
      this.commandType = "start";
      this.commandDisableShow = true;
      this.rowObj = row
    },
    // 移除商家、
    delmerchant(row) {
      this.commandTipText = "是否确定移除该商家？";
      this.commandType = "del";
      this.commandDisableShow = true;
      this.rowObj = row
    },
    // 打开添加回收商弹框
    openAddMerchantDia() {
      this.dialogSearchMerchant(1);
      this.addMerchantDiaShow = true;
    },
    // 确认添加商家
    addMerchantSubmit() {
      this.commandTipText = "确定添加回收商吗？";
      this.commandType = "add";
      this.commandDisableShow = true;
    },
    // 添加商家弹框查询
    dialogSearchMerchant(pageNum) {
      this.merchantDiaLoading = true;
      _api
        .examineQuoteList({
          ...this.diaMerchantForm,
          isOpenExamineQuote: false,
          pageNum,
        })
        .then((res) => {
          this.dialogTableData = res.data.records;
          this.diaMerchantForm.pageNum = res.data.current;
          this.diaMerchantForm.total = res.data.total;
          this.dialogTableData.forEach((item) => {
            if (!this.selectedMerchantList.some((selectedItem) => selectedItem.merchantId === item.merchantId)) {
                this.$nextTick(()=>{
                    this.$refs.addMerchantDia.toggleRowSelection(item, false);
                })
            }
            console.log(this.selectedMerchantList.length,'this.selectedMerchantList.length');
            if (!this.selectedMerchantList.length) {
              this.$refs.addMerchantDia.clearSelection();
            }
          });
        })
        .finally(() => {
          this.merchantDiaLoading = false;
        });
    },
    // 添加商家弹框关闭
    addMerchantDiaClose() {
      this.diaMerchantForm = {
        merchantId: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      this.commandTipText = "";
      this.addMerchantDiaShow = false;
      this.selectedMerchantList = [];
    },
    //删除
    detaleCompy(row) {
      let flag = true;
      this.dialogTableData.forEach(item => {
        if(item.merchantId === row.merchantId){
          flag = false
          this.$refs.addMerchantDia.toggleRowSelection(item, false);
        }
      })
      if (flag) {
        this.selectedMerchantList = this.selectedMerchantList.filter(item => item.merchantId !== row.merchantId);
      }
    },    handSelect(selection, row) {
      console.log(selection, row, "selection, rowselection, rowselection, row");
      this.selectedMerchantList = selection;
    },
    // 安全验证添加商家
    commandSureAdd() {
      if (!this.command) {
        return this.$message.error("请输入谷歌动态码!");
      }
      this.commandButLoading = true;
      if (this.commandType == "add") {
        let merchantIds = [];
        this.selectedMerchantList.forEach((item) => {
          merchantIds.push(item.merchantId);
        });
        let parame = {
          command: this.command,
          merchantIds,
        };
        _api
          .examineQuoteSaveBatch(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("添加成功!");
              this.commandDisableShow = false;
              this.addMerchantDiaShow = false;
              this.merchantForm.pageNum = 1
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      } else if (this.commandType == "del") {
        let parame = {
          command: this.command,
          merchantId: this.rowObj.merchantId,
        };
        _api
          .examineQuoteRemove(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("移除成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      } else if (this.commandType == "water") {
        let parame = {
          command: this.command,
          id: this.rowObj.merchantId,
          isOpen: this.rowObj.isOpenExamineQuoteWatermark,
        };
        _api
          .updateIsOpenWatermark(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("操作成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      } else if (this.commandType == "start") {
        let parame = {
          command: this.command,
          id: this.rowObj.merchantId,
          isOpen: this.rowObj.isExamineUnStart,
        };
        _api
          .updateIsExamineUnStart(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("操作成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      }
    },
    // 安全提示弹框关闭回调
    commandClosed() {
      this.command = ''
      this.searchMerchant(this.merchantForm.pageNum);
    }
  },
};
</script>
<style scoped lang="scss" ref="stylesheet/scss">
.review-settings {
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-je {
    display: flex;
    justify-content: flex-end;
  }
  .merchant-list {
    padding: 20px;
    background: white;
    border-radius: 20px;
    .title {
      font-size: 18px;
      color: #333333;
      font-weight: bold;
    }
  }
  .addparto {
    font-size: 12px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ff8080;
  }
  .tip-centent {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
  .command {
    display: flex;
    align-items: center;
    /deep/.el-input {
      width: 300px;
    }
  }
}
</style>
